import { AuthenticationLogo, CardWithFeedback, applyActionCode, getAuth, useLang, useUrlSearch } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { FeedbackButton } from "../../components/FeedbackButton";
import { AccountLang } from "../../lang/AccountLang";
import { URL } from "../../services/routingService";

export default function VerifyEmailPage(): JSX.Element {
    const { oobCode } = useUrlSearch() as { oobCode?: string };
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState<"success" | "error" | undefined>();
    const lang = useLang<AccountLang>();

    useEffect(() => {
        void (async function checkOobCode() {
            try {
                if (!oobCode) {
                    throw Error("oobCode missing");
                }
                await applyActionCode(getAuth(), oobCode);
                setStatus("success");
            } catch {
                setStatus("error");
            }
            setLoading(false);
        })();
    }, [oobCode]);

    return (
        <CardWithFeedback
            isLoading={loading}
            extra={<AuthenticationLogo />}
            status={status}
            successTitle={lang.authentication.verifyEmailSuccessMessage}
            successExtra={<FeedbackButton label={lang.authentication.login} dest={URL.login} />}
            errorTitle={lang.authentication.oobCodeErrorMessage}
            errorExtra={<FeedbackButton label={lang.shared.back} dest={URL.login} />}
            data-id="verify-email-page"
        />
    );
}
