import { es } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../AccountLang";

/* eslint max-len: 0 */
const esLang: AccountLang = {
    ...es,
    account: {
        apps: {
            title: "Apps",
            plan: "Plan",
            payment: "Forma de pago"
        }
    },
    invitation: {
        invitationNotFound: "Invitación no encontrada", // to review
        wrongMatchingEmail: "La invitación no coincide con su correo electrónico", // to review
        invitationAlreadyAccepted: "Invitación ya aceptada", // to review
        accountNoLongerExists: "La organización ya no existe", // to review
        invitationRejected: "Invitación rechazada", // to review
        invitationCanceled: "Invitación cancelada", // to review
        missingData: "Faltan algunos datos", // to review
        unknownCode: "Código desconocido" // to review
    },
    authentication: {
        loginInstructions: brandName => `Inicia sesión en tu cuenta de ${brandName}`,
        login: "Login",
        rememberMe: "Recuérdame",
        forgotPassword: "¿Has olvidado la contraseña?",
        resetPassword: "Restablece tu contraseña",
        resetPasswordRetry: "Vuelve a restablecer tu contraseña",
        recoverPasswordInstructions: "Introduce tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña.",
        resetPasswordInstructions: "Por favor, elija una nueva contraseña",
        resetPasswordSuccessMessage: "La contraseña se ha restablecido con éxito",
        verifyEmailSuccessMessage: "Su correo electrónico ha sido verificado",
        notRegistered: "¿Aún no se ha registrado?",
        logout: "Cerrar sesión",
        errors: {
            166: "Credenciales incorrectas, correo electrónico y contraseña no coinciden",
            loginFailed: "Fallo en el inicio de sesión"
        },
        loginFailedErrorMessage: "Fallo en el inicio de sesión",
        tryAgain: "Vuelve a intentarlo más tarde. Ya se ha enviado un correo electrónico.",
        actionModeErrorMessage: "Modo inválido",
        oobCodeErrorMessage: "Su solicitud ha caducado o el enlace ya ha sido utilizado",
        resetPasswordErrorMessage: "No se ha podido restablecer la contraseña",
        resetPasswordInfoMessage: "Si tiene una cuenta en la plataforma, vaya a la bandeja de entrada de su correo electrónico para restablecer su contraseña", // to review
        or: "O" // to review
    },
    homePage: {
        title: "¿Qué acción desea realizar?",
        manageAccountSettings: "Gestionar la configuración de la organización"
    },
    domain: {
        title: "Dominio",
        failedToGetDomain: "Fallo al obtener el dominio" // to review
    },
    organisations: {
        title: "Organizaciones",
        searchOrganisation: "Buscar organización",
        noOrganisationFound: "No se ha encontrado ninguna organización en este dominio",
        viewOrganisation: "Ver organización",
        creationDate: "Fecha de creación", // to review
        registeredDate: "Fecha de registro",
        users: "Usuarios"
    },
    sharedSettings: {
        noResultsSearch: "No se han encontrado miembros para su consulta",
        adminRole: "Admin",
        representativeRole: "Representante",
        contributorRole: "Colaborador",
        readOnlyRole: "Sólo lectura"
    },
    profileSettings: {
        warning: {
            emailNotVerified: "Su dirección de correo electrónico no ha sido confirmada todavía."
        },
        title: {
            personalAccount: "Configuración de la organización personal"
        },
        menu: {
            profile: "Perfil", // to review
            preferences: "Preferencias",
            companyAccounts: "Mis organizaciones" // to review
        },
        form: {
            birthdate: "Fecha de nacimiento",
            cellPhone: "Teléfono móvil",
            email: "Correo electrónico",
            currentPassword: "Contraseña actual",
            firstName: "Nombre",
            lastName: "Apellido",
            nationality: "Nacionalidad",
            personalInformation: "Información personal",
            preferences: "Preferencias",
            preferredLanguage: "Idioma preferido"
        },
        companyAccounts: {
            title: "Organizaciones",
            whatIs: "¿Qué es una organización?",
            definition: "Una organización es la empresa a la que perteneces o representas",
            newAccount: "Nueva organización",
            search: "Búsqueda por nombre de organización",
            leave: "Dejar",
            leaveAccount: companyName => `¿Dejar la organización ${companyName}?`,
            leaveAccountDescription: companyName => `¿Confirma que desea abandonar la organización "${companyName}"? Ya no podrá
            acceder a cualquier información relacionada con esta organización.`,
            leaveAccountNonAdminDescription: "Se requerirá una nueva invitación a esta organización si desea volver a unirse a esta organización",
            leaveAccountAdminDescription: `Dado que usted es el único usuario "Admin" de esta organización, puede eliminar la organización por completo para
            todos los usuarios o asignar el rol de Admin a un usuario existente.`,
            newAdminToAssign: "Asignar nuevo admin",
            doNotAssignNewAdmin: "No asignar nuevo admin",
            deleteAccount: "Eliminar organización",
            deleteAccountConfirmation: companyName => `¿Está seguro de que desea eliminar la organización "${companyName}"?`,
            deleteAccountConfirmationDescription: "Todos los datos y usuarios relacionados con esta organización serán eliminados permanentemente.",
            acceptInvitation: "Aceptar",
            rejectInvitation: "Rechazar",
            invitedBy: senderName => `Invitado por ${senderName}`,
            failedToJoinAccount: "Fallo al unirse a la organización",
            failedToRejectInvitation: "Fallo en el rechazo de la invitación"
        }
    },
    accountSettings: {
        title: {
            organisationAccount: "Configuración de la organización"
        },
        rightContent: {
            switchTo: "Cambiar a otra organización"
        },
        menu: {
            organisation: "Organización", // to review
            generalInformation: "Información general",
            userAccess: "Acceso de usuario",
            parties: "Conexiones",
            billingAndSubscriptions: "Facturación y suscripciones",
            git: "Git",
            apiKeys: "Claves API"
        },
        userAccessView: {
            name: "Acceso del usuario",
            whatIs: "¿Qué es un usuario?",
            definition:
                "Un usuario es un colaborador directo de su empresa (por ejemplo: un colega o un representante legal). Cuando les envíes una invitación, asegúrate de que estás estableciendo los derechos de usuario adecuados",
            invitations: "Invitaciones",
            changeRole: "Cambiar rol",
            removeFromAccount: "Eliminar de la organización",
            removeFromAccountConfirmationTitle: user => `Confirmar la eliminación del usuario ${user}`,
            removeFromAccountConfirmationContent: (user, account) => `Esto eliminará al usuario ${user} de la organización ${account}`,
            inviteUser: {
                name: "Invitar al usuario",
                tooltip: "Se requiere el rol de administrador para enviar una invitación.",
                emailPlaceholder: "Dirección de correo electrónico a invitar",
                rolePlaceholder: "Rol",
                errors: {
                    alreadyInAccountErrorMessage: "Este usuario ya forma parte de esta organización.",
                    alreadyInvitedErrorMessage: "Este usuario ya ha recibido una invitación."
                }
            },
            invitePending: "Invitación pendiente...",
            sendInvite: "Enviar",
            resendInvite: "Reenviar la invitación",
            cancelInvitation: "Cancelar Invitación",
            search: "Buscar por correo electrónico o por nombre",
            findMember: "Buscar miembro",
            changeRoleTitle: user => `¿Cambiar el rol de ${user}?`,
            selectRole: "Seleccionar rol",
            adminRoleDescription: "Tiene acceso administrativo completo. Puede invitar y eliminar usuarios y hacer pagos",
            representativeRoleDescription:
                "Puede invitar y contribuir a proporcionar información y datos a todos los servicios accesibles por esta organización.",
            contributorRoleDescription: "Puede contribuir a proporcionar información y datos a todos los servicios accesibles por esta organización",
            readOnlyRoleDescription: "Puede ver todos los datos de los servicios en modo de sólo lectura",
            changeRoleSuccessMessage: "Rol actualizado con éxito",
            changeRoleErrorMessage: "Fallo en la actualización del rol"
        },
        billingView: {
            plansAndSubscriptionsTitle: "Planes y suscripciones",
            billingInformationTitle: "Información de facturación",
            updateSubscription: "Actualizar suscripción",
            updateSubscriptions: "Actualizar suscripción(es)",
            noPlanOrSubscription: "Su organización no tiene actualmente ningún plan o suscripción activos.",
            invoice: {
                failed: "Ocurrió un error al recuperar las facturas",
                invoiceNumber: "Número de factura",
                status: {
                    name: "Status",
                    draft: "Borrador",
                    void: "Vacía",
                    uncollectible: "Incobrable",
                    open: "Abierta",
                    paid: "Pagada"
                },
                date: "Fecha",
                amount: "Importe",
                label: {
                    1: "Factura",
                    plural: () => "Facturas"
                },
                product: "Producto",
                failedToDownloadInvoice: "Ocurrió un error al descargar la factura"
            },
            subscription: {
                failed: "Se ha producido un error al recuperar las suscripciones", // to review
                status: {
                    name: "Status",
                    active: "Activo", // to review
                    canceled: "Cancelado", // to review
                    incomplete_expired: "Caducado", // to review
                    past_due: "Atrasado" // to review
                },
                date: "Fecha", // to review
                amount: "Importe", // to review
                label: {
                    "1": "Suscripción", // to review
                    plural: () => "Suscripciones" // to review
                },
                canceledAt: "Cancelado en" // to review
            },
            paymentMethods: {
                title: "Métodos de pago",
                expirationDate: "Fecha de expiración",
                deletePaymentMethod: "Eliminar método de pago",
                deletePaymentMethodConfirmation: "¿Estás seguro de que deseas eliminar este método de pago?",
                deletePaymentMethodFailed: "Se produjo un error al eliminar el método de pago",
                listPaymentMethodFailed: "Se produjo un error al recuperar los métodos de pago"
            }
        },
        partiesView: {
            name: "Conexiones",
            whatIs: "¿Qué es una conexión?",
            definition:
                "Una conexión es una contraparte, un proveedor o un beneficiario con el que va a trabajar en las auditorías/verificaciones. Una vez invitado, puede ver la información compartida sobre auditorías y garantías, así como el nombre, la dirección y el número de registro de su empresa.",
            invitations: "Invitaciones",
            sentYouAPartyRequest: companyName => `${companyName} desea ser una de sus conexiones`,
            isNowYourParty: companyName => `${companyName} es ahora una de sus conexiones`,
            confirmReject: companyName => `¿Está seguro de querer rechazar la invitación de ${companyName}?`,
            linkPartyAccount: "Aceptar conexión",
            startWorkingTogether: partnerCompanyName =>
                `Por favor, confirme que acepta ser añadido como nueva conexión de “${partnerCompanyName}”. Como conexión, podrán ver la información compartida sobre auditorías y/o fianzas y ver el nombre, la dirección y el número de registro de la empresa de su socio.`,
            selectAnotherAccount: "Si desea vincular otra organización, seleccione la adecuada a continuación",
            noPartyYet: "Aún no has invitado a otras conexiones o no has recibido ninguna invitación.",
            invitationRejected: "Invitación rechazada",
            invitationCancelled: "Invitación cancelada",
            errorOccured: "Se ha producido un error al recuperar la conexión",
            invitationAlreadySent: "Invitación ya enviada",
            reportToAdmin: "Usted no tiene derecho a invitar o aceptar invitaciones de terceros. Póngase en contacto con el administrador de la organización"
        },
        gitView: {
            title: "Conexiones de control de versiones",
            newConnection: "Añadir una nueva conexión",
            installingConnection: "Instalando conexión...",
            deleteConnection: "Eliminar la conexión",
            deleteConnectionConfirmation: "¿Estás seguro de que quieres eliminar esta conexión git?",
            selectConnection: "Seleccione la fuente de conexión:",
            featureComingSoon: "¡Próximamente!",
            lastConnectionDate: "Conectado",
            noResultsSearch: "No se ha encontrado ninguna conexión git",
            installationConfirmationMessage: provider =>
                `Al hacer clic en "Confirmar", se le redirigirá al sitio de ${provider} para completar la instalación.`,
            deletionConfirmationMessage: provider => `Al hacer clic en "Confirmar", se le redirigirá al sitio web de ${provider} para eliminar la conexión.`,
            installed: "Instalado",
            disconnected: "Desconectado",
            fetching: "Obteniendo directorios de código",
            fetchFailed: "Error al obtener los directorios de código",
            lastConnected: date => `Última conexión ${date}`
        },
        apiKeysView: {
            title: "Claves de acceso API",
            newAccessToken: "Generar nuevo token de acceso",
            description: brandName => `Tokens que has generado para acceder a la API de ${brandName}`,
            newApiKeyTitle: "Nuevo token de acceso a la API",
            nameFieldPlaceholder: "Nombre de la clave API...",
            keyEnvironments: {
                sandbox: "Clave Sandbox",
                production: "Clave de producción"
            },
            generate: "Generar",
            recapDescription: "Aviso: Guarde esta clave, será la única vez que la veas.",
            noResultFound: "No se ha encontrado ninguna clave api.",
            deleteApiKeyTitle: "Borrar token de acceso a la API",
            deleteConfirmation: name => `¿Estás seguro de que quieres eliminar la clave API "${name}"?`,
            maxReached: "Has alcanzado el número máximo de claves API."
        }
    },
    onboarding: {
        ...es.onboarding,
        verifyEmail: {
            sent: email => `Te hemos enviado un email de activación a ${email}.`,
            confirm: "Por favor, verifica tu correo electrónico para continuar."
        },
        userProfile: {
            title: "Tu perfil",
            successOnSave: "Tu perfil ha sido actualizado con éxito.",
            errorOnSave: "Fallo al guardar la información de tu perfil."
        },
        invitations: {
            title: "Tus invitaciones",
            description: {
                "1": "Ya has sido invitado por una organización. Selecciónala si deseas unirte:",
                plural: count => `Ya has sido invitado por ${count} organizaciones. Selecciona aquellas a las que desees unirte:`
            },
            joinOrganisation: {
                "0": "Ignorar y crear mi propia organización",
                "1": "Unirse a esta organización",
                plural: count => `Unirse a estas ${count} organizaciones`
            },
            successOnJoin: {
                "1": "Te has unido a una organización con éxito.",
                plural: count => `Te has unido a ${count} organizaciones con éxito.`
            },
            errorOnJoin: {
                "1": "Fallo al unirse a una organización.",
                plural: count => `Fallo al unirse a ${count} organizaciones.`
            }
        },
        account: {
            createAs: {
                title: "Crear como",
                organisationMember: {
                    title: "Miembro de la organización",
                    description: "Crea y administra tu organización."
                },
                representative: {
                    title: "Agente",
                    description: "Cree y gestione una organización en nombre de su cliente."
                }
            },
            accountInfo: {
                title: isRepresentative => `Sobre ${isRepresentative ? "la organización de su cliente" : "su organización"}`
            },
            collaborate: {
                title: isRepresentative => (isRepresentative ? "Contacto del cliente" : "Invite a sus socios"),
                description: isRepresentative => {
                    if (isRepresentative) {
                        return "Por favor, proporcione los correos electrónicos de contacto de su cliente. Podrá unirse a esta organización como administrador, otorgándoles el control total sobre esta nueva organización.";
                    }
                    return "Invite a sus empleados directos a trabajar con usted. Podrán unirse a esta organización, otorgándoles derechos para editar y cargar datos. Siempre puedes cambiar sus funciones más adelante en la página de configuración de la organización, así como enviar otras invitaciones.";
                },
                collaboratorEmail: isRepresentative => `Email ${isRepresentative ? "de contacto del cliente" : "del colaborador"}`,
                addAnotherCollaborator: isRepresentative => `Añadir otro ${isRepresentative ? "contacto de cliente" : "colaborador"}`,
                sendInvitation: {
                    1: "Enviar invitación por e-mail",
                    plural: () => "Enviar invitaciones por e-mail"
                }
            },
            successOnSave: isRepresentative => `${isRepresentative ? "La organización de su cliente" : "Su organización"} ha sido creada con éxito.`,
            errorOnSave: isRepresentative => `Fallo al crear ${isRepresentative ? "la organización de su cliente" : "su organización"}.`
        },
        moreInfoLink: "Más información sobre por qué y cómo usamos su información."
    }
};
export default esLang;
